<template>
  <div class="calendarData">
    <div>
      <inputTextarea
        :data="location"
        :label="$t('settings.location.title')"
        :help="$t('settings.location.info')"
        :callback="setLocation"
        :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
        :placeholder="$t('settings.location.title')"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      location: this.$store.state.business.unsaved.location
    };
  },
  methods: {
    setLocation(val) {
      this.location = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.location = this.location;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
